.producerBallotCard {
  background-color: #e6e6e6;
}
.producerBallotCardBody p {
  text-align: center;
  margin-bottom: 0px;
}
.producerBallotCardBody p strong {
  display: block;
  font-size: 22px;
}

.producerBallotCardBody p span {
  font-weight: lighter;
  font-size: 22px;
}
