.gmBPvf {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.gelpCx {
  overflow: unset !important;
}

.freightQuotaInsert .sc-fLlhyt {
  margin-bottom: 300px !important;
}
