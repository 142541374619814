.rdt_TableHeadRow .rdt_TableCol:first-child {
  border-radius: 30px 0 0 0;
}
.rdt_TableHeadRow .rdt_TableCol:last-child {
  border-radius: 0 30px 0 0;
}
.ntaTableCheckbox {
  color: white !important;
}

.rdt_TableHeadRow .eUeqdG {
  font-size: 1.25rem !important;
}

.rdt_TableCell {
  padding-left: 2px !important;
  padding-right: 2px !important;
}