.clientAvailableDay {
  padding: 10px 12px;
  margin: 0 5px;
  transition: linear all 0.25s;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}
.clientAvailableDay.activeDay {
  color: #fff;
  background: #071c49;
}
.clientAvailableDay.inactiveDay {
  color: white;
  background-color: #737277;
}
