@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

body {
  font-family: "Open Sans", sans-serif;
}

.bgLogin {
  background: transparent url(css/img/bgLogin.png) no-repeat center / cover;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  font-family: "Open Sans", sans-serif;
  overflow: auto;
}
@media (max-width: 900px) {
  .loginWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-left: 20px !important;
    margin-right: 20px !important; */
  }
  .loginWrapper img.logo {
    width: 20rem !important;
  }
  .loginWrapper h2 {
    font-size: 1.2rem !important;
  }
  .loginWrapper p {
    font-size: 0.8rem !important;
    line-height: 1rem !important;
    width: 85%;
  }
  .loginWrapper form {
    width: 85%;
  }
  .loginWrapper input,
  .loginWrapper label {
    font-size: 0.9rem !important;
  }
  .loginWrapper input {
    padding: 0.5rem 0.7rem !important;
  }
  .loginWrapper img.passEye {
    width: 1rem !important;
  }
  .loginWrapper .passEyeContainer {
    margin-top: -2rem !important;
  }
  .loginWrapper .checkContainer {
    margin-top: 2rem !important;
    width: 100%;
  }
  .loginWrapper .btnLogin {
    font-size: 1rem !important;
    margin-top: 1rem !important;
  }
  .loginWrapper .forgotPassContainer {
    width: 100%;
  }
  .loginWrapper .forgotPass {
    width: 100% !important;
  }
  .loginWrapper .rememberSession {
    font-size: 0.8rem !important;
  }

  /*Estilos Generales*/
  .menuContainer {
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-template-rows: initial !important;
    grid-template-columns: initial !important;
  }
  .menuContainer .btnDisplayMenu {
    display: block !important;
    padding-right: 2rem !important;
  }

  .menuContainer img.logoMenu {
    width: 8rem !important;
    padding: 0.5rem 0.5rem !important;
  }
  .menuContainer .itemMenu {
    display: none;
  }
  .menuContainer .menuContainerResponsive {
    z-index: 4000;
    position: absolute;
    background-color: white;
    top: 4rem !important;
    width: 100%;
  }

  .tituloPantallas {
    font-size: 1.5rem !important;
    width: 15rem !important;
  }
  .formGeneral .subtituloForm {
    font-size: 1.2rem !important;
  }
  .formGeneral .inputsForm,
  .formGeneral .checkRoles {
    grid-template-columns: initial !important;
    gap: 0 !important;
  }
  .containerTituloBtn {
    align-items: center;
  }
  .containerList .inputsForm {
    grid-template-columns: initial !important;
    gap: 0 !important;
  }
  .containerList .btnList2 {
    display: block !important;
    padding: 0.5rem !important;
    width: 35%;
    margin: 0;
  }
  .containerList .btnList {
    display: none;
  }
  .rdt_TableCell:last-child {
    display: flex !important;
    flex-direction: column !important;
  }
  .rdt_TableHeadRow .eUeqdG {
    font-size: 1rem !important;
  }
  .containerList {
    width: 85% !important;
  }
  .sc-hHLeRK.gFYXSL.rdt_TableBody div {
    font-size: 1rem;
  }
  .btnModal {
    grid-template-columns: initial !important;
  }
  .containerList .containerMoreBtns {
    grid-template-columns: initial !important;
    gap: 0% !important;
    display: block;
  }

  .textoContainerHome {
    padding: 0rem 2.5rem !important;
  }
  .vehicles-table th {
    font-size: 0.9rem !important;
  }
  .vehicles-table td {
    font-size: 0.7rem !important;
  }
  .containerTable {
    position: relative !important;
    width: 260% !important;
  }
}

.textoContainerHome {
  padding: 0rem 10rem;
}

.inputBorder {
  border: 1px solid #c4c4c4;
}

.vehicles-table {
  margin-top: 20px;
}

.vehicles-table .searchable {
  padding-bottom: 300px;
}

.vehicles-table th:first-child {
  border-top-left-radius: 20px;
}

.vehicles-table th:last-child {
  border-top-right-radius: 20px;
}

.vehicles-table th {
  font-size: 1.5rem;
  color: #071c49;
  font-weight: 600;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-bottom: none;
  text-align: center;
}

.vehicles-table td {
  font-size: 1.2rem;
  text-align: center;
}

.buttonIcon:hover {
  background-color: #f1f1f1;
}

.buttonIcon:disabled {
  color: #c4c4c4;
  cursor: not-allowed;
}

.buttonIcon {
  transition: linear all 0.2s;
}

.tableInput {
  margin-top: 0;
}
.tableInput input,
.tableInput select {
  margin: 1px 0;
}

select:disabled,
input:disabled {
  cursor: not-allowed;
  background-color: #cfcfcf;
}

.ntaTableDataSet {
  width: 100%;
  margin: 10px 0;
}

.ntaTableDataSet td {
  padding: 10px 0;
  width: 50%;
  font-size: x-large;
}

.ntaTableDataSet td strong {
  color: #071c49;
  font-weight: bold;
}

.modal-title {
  width: 100%;
}

.rolesList {
  padding-left: 30px;
  list-style-type: circle;
}

.inputAndButton {
  grid-template-columns: 1fr 1fr 50px;
}

.inputAndButtonDestinations {
  grid-template-columns: 1fr 50px;
}

.buttonPlus {
  margin-top: 4.75rem;
}

.buttonTrash {
  margin-top: 2rem;
  font-size: 2rem;
}

.addProductsLink {
  text-decoration: none;
}

.addProductsLink:hover {
  text-decoration: none;
}

.addProductsLink span {
  padding-bottom: 3px;
  border-bottom: solid 1px #071c49;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.addProductsLink h3 {
  padding-bottom: 3px;
  border-bottom: solid 1px #071c49;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.backgroundInputProducts {
  background-color: rgba(7, 28, 73, 0.15);
  border-radius: 20px;
  margin: 2rem;
  padding: 2rem 2rem;
}

.inputProductsContainer {
  margin-top: -0.5rem !important;
}

.inputProductsContainerPt2 {
  margin-top: 0rem !important;
}

.inputProductsContainer div {
  margin-top: 0.75rem !important;
}

.backgroundInputProducts label {
  font-size: 1.125rem;
  line-height: 1.25rem;
}

.backgroundInputProducts input {
  /*padding: 0.75rem !important;*/
}

.titleWarehouseList {
  grid-template-columns: 1fr 4rem;
}

.hopperFull {
  grid-template-rows: 115px 0px;
}

.hopperFraction {
  padding: 0 1.5rem !important;
  height: 30px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.modalProductInsert {
  max-height: 35rem;
  overflow-y: auto;
}

.modalQualityCertificate {
  max-height: 35rem;
  overflow-y: auto;
}

.titleCertificate {
  grid-template-columns: 23% 50% 27%;
}

.certificateProduct {
  grid-template-columns: 75% 25%;
}

.modalKardexBody {
  max-height: 35rem;
  overflow-y: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hopperDivisionBorder {
  border-bottom: 2px solid black;
}

.activeUnitSelected {
  font-weight: bold;
  color: #071c49;
  cursor: pointer;
}

.activeUnitSelected:hover {
  color: #06173d;
  cursor: pointer;
}

.eUeqdG {
  white-space: unset !important;
  text-overflow: unset !important;
}

@media screen and (max-width: 1400px) {
  html {
    font-size: 14px;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control,
  .css-1insrsq-control {
    padding: 0.375rem 8px !important;
  }
}

button:disabled {
  background-color: #c4c4c4;
}
