.nta-submenu {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 6px 0px #00000040;
  z-index: 10;
}

.nta-submenu li {
  text-align: left;
  padding: 5px 10px;
  text-transform: uppercase;
  border-bottom: 1px solid #071c49;
}

.nta-submenu li:last-child {
  border-bottom: none;
}

.nta-submenu li:hover {
  background-color: #f0f0f0;
}
